function loadScript(scriptUrl) {
  const script = document.createElement('script')
  script.src = scriptUrl
  document.body.appendChild(script)

  return new Promise((res, rej) => {
    script.onload = function() {
      res()
    }
    script.onerror = function() {
      rej()
    }
  })
}

export const onClientEntry = () => {
  window.onload = () => {
    loadScript('/js/core.min.js').then(() => {
      loadScript('/js/script.js')
    })
  }
}

export const onRouteUpdate = () => {
  loadScript('/js/script.js')
}